import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ModalForm = _resolveComponent("ModalForm");
  return _openBlock(), _createBlock(_component_ModalForm, {
    modelValue: $setup.modalVisible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.modalVisible = $event),
    onSubmit: $setup.handleFormSubmit,
    onCancel: $setup.handleFormCancel,
    label: $props.label,
    "label-desc": $props.labelDesc,
    "parent-id": $props.parentId,
    accountId: String($props.accountId),
    type: $props.type,
    errorMessage: $setup.errorMessage
  }, null, 8, ["modelValue", "onSubmit", "onCancel", "label", "label-desc", "parent-id", "accountId", "type", "errorMessage"]);
}