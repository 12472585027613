import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f8370746"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "flex-grow-1"
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  class: "d-flex justify-content-between mt-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FoldersList = _resolveComponent("FoldersList");
  const _component_ModelComponent = _resolveComponent("ModelComponent");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [!$setup.loading ? (_openBlock(), _createBlock(_component_FoldersList, {
    key: 0,
    folders: $setup.folders,
    accountId: String($setup.accountId)
  }, null, 8, ["folders", "accountId"])) : (_openBlock(), _createElementBlock("div", _hoisted_2, "Loading..."))]), _createElementVNode("footer", _hoisted_3, [!$setup.loading ? (_openBlock(), _createBlock(_component_ModelComponent, {
    key: 0,
    onRecordAdded: $setup.addRecord,
    "parent-id": $props.account.disks[0].local_id,
    "account-id": $props.account.id,
    label: "Имя",
    "label-desc": "Описание",
    type: "folder",
    icon: "📂"
  }, null, 8, ["onRecordAdded", "parent-id", "account-id"])) : _createCommentVNode("", true)])], 64);
}