import { computed, inject, onBeforeMount, ref } from "vue";
import { useRoute } from 'vue-router';
import LoadingPage from "@/views/LoadingPage.vue";
import AccountLink from "@/components/AccountLink.vue";
import SettingsLink from "@/components/SettingsLink.vue";
import AccessError from "@/views/AccessError.vue";
import CreateAccount from "@/views/SignUp.vue";
import HomeView from "@/views/HomeView.vue";
import DiskList from "@/components/DiskList.vue";
import ShareVIew from "@/views/ShareView.vue";
import SignUp from "@/views/SignUp.vue";
import api from "@/axios";
import OwnerBlock from "@/components/OwnerBlock.vue";
export default {
  name: 'App',
  components: {
    OwnerBlock,
    SignUp,
    ShareVIew,
    DiskList,
    HomeView,
    CreateAccount,
    AccessError,
    SettingsLink,
    AccountLink,
    LoadingPage
  },
  setup() {
    const route = useRoute();
    const account = ref(null);
    const isDeny = ref(false);
    const isLoading = ref(true);
    const isShare = ref(false);
    const showSignUp = ref(false);
    const init = inject('initData');
    const user = ref(init.user);
    const tgUser = init.user;
    const loading = async () => {
      api.post('/api/v1/validate', tgUser).then(response => {
        if (response.status === 200) {
          return api.get(`/api/v1/accounts/telegram_id/${tgUser.id}`);
        } else {
          isDeny.value = true;
        }
      }).then(response => {
        if (response.status === 200) {
          ///sdk.value.ready();
          account.value = response.data;
        } else {
          isDeny.value = true;
        }
      }).catch(error => {
        if (error.response && error.response.status === 404) {
          if (init.start_param) {
            isShare.value = true;
          } else {
            showSignUp.value = true;
          }
        } else {
          isDeny.value = true;
          isLoading.value = false;
        }
      }).finally(() => {
        new Promise(resolve => setTimeout(resolve, 100));
        if (init.start_param) {
          isShare.value = true;
        }
        isLoading.value = false;
      });
    };
    const isHomeView = computed(() => {
      return route.path === '/' && !isShare.value && !showSignUp.value;
    });
    onBeforeMount(async () => {
      await loading();
    });
    const handleError = error => {
      isDeny.value = true;
      console.log(error);
      console.error('Error received in App:', isDeny);
    };
    const handleAccountCreated = newAccount => {
      account.value = newAccount;
      showSignUp.value = false;
    };
    const stopShare = () => {
      isShare.value = false;
      isHomeView.value = !!account.value;
      showSignUp.value = !account.value;
    };
    return {
      user,
      account,
      isDeny,
      isShare,
      isLoading,
      showSignUp,
      isHomeView,
      stopShare,
      handleError,
      handleAccountCreated
    };
  }
};