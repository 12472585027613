import { ref, onMounted } from 'vue';
import api from "@/axios";
export default {
  name: 'CommonStat',
  props: {
    account: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const stats = ref({
      maxDisks: 0,
      maxFolders: 0,
      maxLinks: 0,
      diskNumber: 0,
      hasFolders: 0,
      hasLinks: 0
    });
    const loading = ref(true);
    const fetchStats = async () => {
      try {
        if (!props.account) {
          return;
        }
        const response = await api.get('/api/v1/accounts/statistic', {
          headers: {
            Authorization: `Bearer ${props.account.id}`
          }
        });
        stats.value = response.data;
      } catch (error) {
        console.error('Error fetching stats:', error);
      } finally {
        loading.value = false;
      }
    };
    onMounted(fetchStats);
    return {
      stats,
      loading
    };
  }
};