import { ref, watch } from 'vue';
export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    },
    accountId: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    labelDesc: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue', 'submit'],
  setup(props, {
    emit
  }) {
    const form = ref({
      name: '',
      description: '',
      type: props.type,
      parent_id: props.parentId,
      account_id: props.accountId
    });
    const visible = ref(props.modelValue);
    const formElement = ref(null);
    watch(() => props.modelValue, newVal => {
      visible.value = newVal;
    });
    watch(() => props.parentId, newVal => {
      form.value.parent_id = newVal;
    });
    watch(() => props.accountId, newVal => {
      form.value.account_id = newVal;
    });
    watch(() => props.type, newVal => {
      form.value.type = newVal;
    });
    watch(visible, newVal => {
      emit('update:modelValue', newVal);
    });
    const handleSubmit = event => {
      const formEl = formElement.value;
      if (formEl && !formEl.checkValidity()) {
        event.preventDefault();
        formEl.reportValidity();
        return;
      }
      const formData = new FormData();
      formData.append('name', form.value.name);
      formData.append('type', form.value.type);
      formData.append('description', form.value.description);
      formData.append('parent_id', form.value.parent_id);
      formData.append('account_id', form.value.account_id);
      emit('submit', formData);
      emit('update:modelValue', false);
    };
    return {
      form,
      visible,
      handleSubmit,
      formElement
    };
  }
};