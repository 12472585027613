import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "modal-form",
  ref: "formElement"
};
const _hoisted_2 = {
  class: "mb-3"
};
const _hoisted_3 = {
  for: "name",
  class: "form-label"
};
const _hoisted_4 = {
  class: "mb-3"
};
const _hoisted_5 = {
  for: "description",
  class: "form-label"
};
const _hoisted_6 = {
  class: "mb-3"
};
const _hoisted_7 = {
  key: 0,
  class: "alert alert-danger"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BModal = _resolveComponent("BModal");
  return _openBlock(), _createBlock(_component_BModal, {
    modelValue: $setup.visible,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $setup.visible = $event),
    hideHeader: true,
    okTitle: "Сохранить",
    cancelTitle: "Отменить",
    footerClass: "modal-footer-ok",
    onOk: $setup.handleSubmit
  }, {
    default: _withCtx(() => [_createElementVNode("form", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("label", _hoisted_3, _toDisplayString($props.label), 1), _withDirectives(_createElementVNode("input", {
      type: "text",
      class: "form-control",
      id: "name",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.form.name = $event),
      required: ""
    }, null, 512), [[_vModelText, $setup.form.name]])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("label", _hoisted_5, _toDisplayString($props.labelDesc), 1), _withDirectives(_createElementVNode("input", {
      type: "text",
      class: "form-control",
      id: "description",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.form.description = $event)
    }, null, 512), [[_vModelText, $setup.form.description]])]), _createElementVNode("div", _hoisted_6, [$props.errorMessage ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString($props.errorMessage), 1)) : _createCommentVNode("", true)]), _withDirectives(_createElementVNode("input", {
      type: "hidden",
      name: "parent_id",
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $setup.form.parent_id = $event)
    }, null, 512), [[_vModelText, $setup.form.parent_id]]), _withDirectives(_createElementVNode("input", {
      type: "hidden",
      name: "account_id",
      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $setup.form.account_id = $event)
    }, null, 512), [[_vModelText, $setup.form.account_id]]), _withDirectives(_createElementVNode("input", {
      type: "hidden",
      name: "type",
      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $setup.form.type = $event)
    }, null, 512), [[_vModelText, $setup.form.type]])], 512)]),
    _: 1
  }, 8, ["modelValue", "onOk"]);
}