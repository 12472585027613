import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["disabled"];
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    style: _normalizeStyle($setup.containerStyle())
  }, [_createElementVNode("header", {
    style: _normalizeStyle($setup.headerStyle())
  }, [_createElementVNode("h1", {
    style: _normalizeStyle($setup.textStyle())
  }, "Добро пожаловать!", 4)], 4), _createElementVNode("div", {
    style: _normalizeStyle($setup.contentStyle())
  }, [_createElementVNode("button", {
    style: _normalizeStyle($setup.buttonStyle()),
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.createAccount && $setup.createAccount(...args)),
    disabled: $setup.loading
  }, [$setup.loading ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Loading...")) : (_openBlock(), _createElementBlock("span", _hoisted_3, "Создать аккаунт"))], 12, _hoisted_1)], 4)], 4);
}