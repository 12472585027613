import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  class: "d-flex flex-column h-100 p-2 gap-2"
};
const _hoisted_4 = {
  class: "d-flex justify-content-between align-items-center p-2 header"
};
const _hoisted_5 = {
  key: 0
};
const _hoisted_6 = {
  key: 1
};
const _hoisted_7 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LoadingPage = _resolveComponent("LoadingPage");
  const _component_BToastOrchestrator = _resolveComponent("BToastOrchestrator");
  const _component_AccountLink = _resolveComponent("AccountLink");
  const _component_SettingsLink = _resolveComponent("SettingsLink");
  const _component_HomeView = _resolveComponent("HomeView");
  const _component_ShareVIew = _resolveComponent("ShareVIew");
  const _component_SignUp = _resolveComponent("SignUp");
  const _component_router_view = _resolveComponent("router-view");
  return $setup.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_LoadingPage)])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("header", _hoisted_4, [_createVNode(_component_BToastOrchestrator), !$setup.isShare ? (_openBlock(), _createBlock(_component_AccountLink, {
    key: 0,
    user: $setup.user
  }, null, 8, ["user"])) : _createCommentVNode("", true), !$setup.isShare ? (_openBlock(), _createBlock(_component_SettingsLink, {
    key: 1
  })) : _createCommentVNode("", true)]), $setup.isHomeView ? (_openBlock(), _createElementBlock("div", _hoisted_5, [$setup.account ? (_openBlock(), _createBlock(_component_HomeView, {
    key: 0,
    account: $setup.account
  }, null, 8, ["account"])) : _createCommentVNode("", true)])) : $setup.isShare ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_ShareVIew, {
    onCloseShare: $setup.stopShare
  }, null, 8, ["onCloseShare"])])) : $setup.showSignUp ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_SignUp, {
    onAccountCreated: $setup.handleAccountCreated,
    onError: $setup.handleError
  }, null, 8, ["onAccountCreated", "onError"])])) : _createCommentVNode("", true), _createVNode(_component_router_view)])]));
}