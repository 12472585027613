import { createRouter, createWebHistory } from 'vue-router';
import HomeView from './views/HomeView.vue';
import AccessError from './views/AccessError.vue';
import SignUp from './views/SignUp.vue';
import FolderItem from "@/components/FolderItem.vue";
import ShareView from "@/views/ShareView.vue";

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: HomeView
    },
    {
        path: '/access-error',
        name: 'AccessError',
        component: AccessError
    },
    {
        path: '/share',
        name: 'ShareView',
        component: ShareView
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: SignUp
    },
    {
        path: '/folder/:diskId/:accountId/:id',
        name: 'folder',
        component: FolderItem,
        props: true
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
