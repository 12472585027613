import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-363997a3"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "row flex-grow-1"
};
const _hoisted_2 = {
  class: "col-12 d-flex flex-column gap-2"
};
const _hoisted_3 = {
  class: "content-block flex-grow-1 d-flex flex-column"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CommonStat = _resolveComponent("CommonStat");
  const _component_DiskList = _resolveComponent("DiskList");
  return _openBlock(), _createElementBlock("main", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$props.account && Object.keys($props.account).length ? (_openBlock(), _createBlock(_component_CommonStat, {
    key: 0,
    account: $props.account
  }, null, 8, ["account"])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [$props.account && Object.keys($props.account).length ? (_openBlock(), _createBlock(_component_DiskList, {
    key: 0,
    account: $props.account
  }, null, 8, ["account"])) : _createCommentVNode("", true)])])]);
}