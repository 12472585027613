import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { inject, ref } from 'vue';
import api from "@/axios";
export default {
  name: 'CreateAccount',
  emits: ['error', 'accountCreated'],
  setup(_, {
    emit
  }) {
    const telegram = inject('tgSDK');
    const loading = ref(false);
    const createAccount = async () => {
      loading.value = true;
      const formData = new URLSearchParams(telegram.initData).toString();
      try {
        const response = await api.post('/api/v1/accounts', formData);
        if (response.status === 200) {
          emit('accountCreated', response.data);
        } else {
          emit('error', 'Failed to create account');
        }
      } catch (error) {
        console.error('Error creating account:', error);
        emit('error', error);
      } finally {
        loading.value = false;
      }
    };
    const containerStyle = () => ({
      backgroundColor: telegram.bg_color,
      color: telegram.text_color,
      height: '90vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 0,
      padding: 0
    });
    const headerStyle = () => ({
      backgroundColor: telegram.header_bg_color,
      width: '100%',
      textAlign: 'center',
      padding: '1rem'
    });
    const contentStyle = () => ({
      backgroundColor: telegram.secondary_bg_color,
      width: '100%',
      textAlign: 'center'
    });
    const textStyle = () => ({
      color: telegram.text_color
    });
    const buttonStyle = () => ({
      backgroundColor: telegram.button_color,
      color: telegram.button_text_color,
      border: 'none',
      padding: '1rem 2rem',
      fontSize: '1.2rem',
      cursor: 'pointer',
      borderRadius: '0.5rem'
    });
    return {
      loading,
      createAccount,
      containerStyle,
      headerStyle,
      contentStyle,
      textStyle,
      buttonStyle
    };
  }
};