import { ref, inject, reactive, onMounted } from 'vue';
import { ContextMenu, ContextMenuItem } from '@imengyu/vue3-context-menu';
import ModelComponent from "@/components/ModelComponent.vue";
import { useToast } from 'bootstrap-vue-next';
import { useRouter } from 'vue-router';
import api from "@/axios";
export default {
  name: 'FoldersItem',
  props: {
    id: String,
    diskId: String,
    accountId: {
      type: String,
      required: true
    }
  },
  components: {
    ModelComponent,
    ContextMenu,
    ContextMenuItem
  },
  setup(props) {
    const show = useToast();
    const router = useRouter();
    const sdk = inject('tgSDK');
    sdk.BackButton.show();
    sdk.BackButton.onClick(() => {
      router.go(-1);
    });

    /*
        sdk.SecondaryButton.position = 'right';
        sdk.SecondaryButton.show()
        sdk.SecondaryButton.setText('Поделиться 🌐')
        sdk.SecondaryButton.onClick(() => {
        })
    */

    const isShow = ref(false);
    const loading = ref(true);
    const loadingItemId = ref(null);
    const selectedItemId = ref(null);
    const selectedItemName = ref('');
    const selectedItemDesc = ref('');
    const optionsComponent = reactive({
      x: 0,
      y: 0,
      customClass: "context-menu-main",
      zIndex: 3,
      minWidth: 230
    });
    const folder = reactive({
      id: 0,
      account_id: 0,
      parent_id: 0,
      local_id: 0,
      name: '',
      description: '',
      path: '',
      type: 0,
      sort_order: 0,
      shared: 0,
      created_at: '',
      updated_at: null,
      children: {}
    });
    const onContextMenu = (e, itemId, itemName, itemDesc) => {
      e.preventDefault();
      e.stopPropagation();
      optionsComponent.x = e.clientX;
      optionsComponent.y = e.clientY;
      selectedItemId.value = itemId;
      selectedItemName.value = itemName;
      selectedItemDesc.value = itemDesc;
      isShow.value = true;
    };
    const showToast = () => {
      show({
        props: {
          body: 'Ссылка скопирована в буфер обмена',
          value: 1500,
          variant: 'success'
        }
      });
    };
    const showPopup = child => {
      sdk.showPopup({
        message: "Перейти по ссылке?\n" + child.name,
        buttons: [{
          id: 'yes',
          type: 'default',
          text: 'Да'
        }, {
          id: 'no',
          type: 'default',
          text: 'Нет'
        }]
      }, buttonId => {
        if (buttonId === 'yes') {
          window.open(child.name, '_blank');
        }
      });
    };
    const handleItemClick = child => {
      showPopup(child);
    };
    const shareLink = () => {
      sdk.openTelegramLink('https://t.me/share/url?url=' + encodeURIComponent(selectedItemName.value) + "\r\n&text=" + encodeURIComponent(selectedItemDesc.value));
    };
    const deletePrompt = () => {
      if (!sdk || typeof sdk.showPopup !== 'function') {
        alert('Telegram sdk not loaded');
        return;
      }
      if (sdk.platform === 'unknown' || sdk.platform === 'web') {
        if (window.confirm('Действительно удалить?')) {
          deleteItem();
        }
        return;
      }
      sdk.showPopup({
        message: 'Действительно удалить?',
        buttons: [{
          id: 'yes',
          type: 'default',
          text: 'Да'
        }, {
          id: 'no',
          type: 'default',
          text: 'Нет'
        }]
      }, buttonId => {
        if (buttonId === 'yes') {
          deleteItem();
        }
      });
    };
    const deleteItem = async () => {
      try {
        loadingItemId.value = selectedItemId.value;
        const response = await api.delete(`/api/v1/nodes/${selectedItemId.value}`, {
          headers: {
            Authorization: `Bearer ${props.accountId}`
          }
        });
        Object.assign(folder, response.data);
      } catch (error) {
        console.error('Error fetching disks:', error);
      } finally {
        loadingItemId.value = null;
      }
    };
    const fetchDisk = async () => {
      try {
        const response = await api.get(`/api/v1/folders/${props.id}`, {
          headers: {
            Authorization: `Bearer ${props.accountId}`
          }
        });
        Object.assign(folder, response.data);
      } catch (error) {
        console.error('Error fetching disk:', error);
      } finally {
        loading.value = false;
      }
    };
    const shortLink = text => {
      if (text.length > 25) {
        return text.slice(0, 25) + '...';
      }
      return text;
    };
    onMounted(() => {
      fetchDisk();
    });
    return {
      showToast,
      showPopup,
      shareLink,
      deleteItem,
      deletePrompt,
      handleItemClick,
      onContextMenu,
      fetchDisk,
      shortLink,
      isShow,
      folder,
      loading,
      loadingItemId,
      optionsComponent,
      selectedItemName,
      selectedItemDesc
    };
  },
  data() {
    return {
      copied: false
    };
  },
  methods: {
    copyToBuffer() {
      const textarea = document.createElement('textarea');
      textarea.value = this.selectedItemName + "\r\n" + this.selectedItemDesc;
      textarea.style.position = 'fixed';
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      try {
        document.execCommand('copy');
        this.showToast();
      } catch (err) {
        console.error('Ошибка при копировании: ', err);
      }
      document.body.removeChild(textarea);
    },
    goBack() {
      this.$router.go(-1);
    },
    addRecord(record) {
      this.folder.children = record.children;
    }
  }
};