import { inject } from 'vue';
export default {
  setup() {
    const sdk = inject('tgSDK');
    const showPopup = () => {
      sdk.showPopup({
        message: "Пока в разаработке",
        buttons: [{
          id: "1",
          type: "default",
          text: "OK"
        }]
      }, function (buttonId) {
        console.log("Button clicked: ", buttonId);
      });
    };
    return {
      showPopup
    };
  }
};