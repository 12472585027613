import { inject, onMounted, ref } from 'vue';
import ModalForm from './ModalForm.vue';
import api from "@/axios";
import { onBeforeMount } from "@vue/runtime-core";
export default {
  components: {
    ModalForm
  },
  emits: ['recordAdded'],
  props: {
    parentId: {
      type: Number,
      required: true
    },
    accountId: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    labelDesc: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  },
  setup(props, {
    emit
  }) {
    const modalVisible = ref(false);
    const errorMessage = ref('');
    const sdk = inject('tgSDK');
    onBeforeMount(() => {
      sdk.MainButton.show();
      sdk.MainButton.setText(`Добавить ${props.icon}`);
      sdk.MainButton.onClick(() => {
        modalVisible.value = true;
      });
    });
    const handleFormSubmit = formData => {
      sdk.MainButton.showProgress(true);
      api.post('/api/v1/nodes', formData, {
        headers: {
          Authorization: `Bearer ${props.accountId}`
        }
      }).then(response => {
        errorMessage.value = '';
        if (response.data['id']) {
          emit('record-added', response.data);
        } else {
          // fixme add telegram alert
        }
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          errorMessage.value = error.response.data.reason || 'Unknown reason';
          modalVisible.value = true;
        } else {
          console.error(error);
        }
      }).finally(() => {
        sdk.MainButton.hideProgress();
      });
    };
    const handleFormCancel = () => {
      errorMessage.value = '';
    };
    return {
      modalVisible,
      errorMessage,
      handleFormSubmit,
      handleFormCancel
    };
  }
};