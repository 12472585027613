import DiskList from "@/components/DiskList.vue";
import CommonStat from "@/components/CommonStat.vue";
export default {
  name: 'HomeView',
  components: {
    DiskList,
    CommonStat
  },
  props: {
    account: {
      type: Object,
      required: true
    }
  }
};