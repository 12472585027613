import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { ContextMenu, ContextMenuItem } from '@imengyu/vue3-context-menu';
import { ref, inject, watch } from 'vue';
import api from "@/axios";
import { useToast } from "bootstrap-vue-next";
export default {
  name: 'FoldersList',
  components: {
    ContextMenu,
    ContextMenuItem
  },
  props: {
    folders: {
      type: Array,
      required: true
    },
    accountId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const {
      toast
    } = useToast();
    const sdk = inject('tgSDK');
    sdk.SecondaryButton.hide();
    if (sdk.BackButton.isVisible) {
      sdk.BackButton.hide();
    }
    const children = ref([]);
    const show = ref(false);
    const loading = ref(false);
    const diskId = ref(0);
    const clickedId = ref(0);
    const deletingId = ref(0);
    const clickedDesc = ref(null);
    const loadingItemId = ref(null);
    const optionsComponent = ref({
      iconFontClass: 'fa',
      customClass: "context-menu-main",
      zIndex: 3,
      minWidth: 230,
      x: 0,
      y: 0
    });
    watch(() => props.folders, newChildren => {
      children.value = [...newChildren];
    }, {
      immediate: true
    });
    const onContextMenu = (e, id, dId, desc) => {
      e.preventDefault();
      optionsComponent.value.x = e.clientX;
      optionsComponent.value.y = e.clientY;
      diskId.value = dId;
      clickedId.value = id;
      clickedDesc.value = desc;
      show.value = true;
    };
    const deletePrompt = () => {
      if (!sdk || typeof sdk.showPopup !== 'function') {
        alert('Telegram sdk not loaded');
        return;
      }
      if (sdk.platform === 'unknown' || sdk.platform === 'web') {
        if (window.confirm('Действительно удалить?')) {
          deleteItem();
        }
        return;
      }
      sdk.showPopup({
        message: 'Перейти по ссылке?',
        buttons: [{
          id: 'yes',
          type: 'default',
          text: 'Да'
        }, {
          id: 'no',
          type: 'default',
          text: 'Нет'
        }]
      }, buttonId => {
        if (buttonId === 'yes') {
          deleteItem();
        }
      });
    };
    const deleteItem = async () => {
      const childId = clickedId.value;
      deletingId.value = childId;
      loadingItemId.value = childId;
      try {
        await api.delete(`/api/v1/folders/${childId}`, {
          headers: {
            Authorization: `Bearer ${props.accountId}`
          }
        }).then(response => {
          console.log('Response:', response.data);
        }).catch(error => {
          console.error('Error:', error);
        });
        children.value = children.value.filter(child => child.local_id !== childId);
      } catch (error) {
        console.error('Error deleting child:', error);
      } finally {
        show.value = false;
        deletingId.value = 0;
      }
    };
    const share = async () => {
      loading.value = true;
      loadingItemId.value = clickedId.value;
      let url = localStorage.getItem('share_' + props.accountId + '_' + diskId.value + '_' + clickedId.value);
      if (url) {
        shareLink(url);
        return;
      }
      const body = new URLSearchParams();
      body.append('parent_id', String(diskId.value));
      body.append('local_id', String(clickedId.value));
      await api.post(`/api/v1/shares`, body, {
        headers: {
          Authorization: `Bearer ${props.accountId}`
        }
      }).then(response => {
        let url = response.data.share;
        if (url !== undefined) {
          localStorage.setItem('share_' + props.accountId + '_' + diskId.value + '_' + clickedId.value, url);
          shareLink(url);
        } else {
          toast({
            props: {
              body: 'Ошбика создания shared ссылки',
              value: 1500,
              variant: 'danger'
            }
          });
        }
      }).catch(error => {
        console.error('Error:', error);
      }).finally(() => {
        loading.value = false;
        loadingItemId.value = null;
      });
    };
    const shareLink = url => {
      sdk.openTelegramLink('https://t.me/share/url?url=' + encodeURIComponent(url) + "\r\n&text=" + encodeURIComponent(clickedDesc.value));
    };
    return {
      children,
      show,
      loading,
      optionsComponent,
      diskId,
      clickedId,
      deletingId,
      loadingItemId,
      share,
      onContextMenu,
      deletePrompt
    };
  }
};