import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4c19b894"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "folders-place list-group list-group-flush"
};
const _hoisted_2 = {
  class: "d-flex w-100 justify-content-between"
};
const _hoisted_3 = {
  class: "mb-1"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  key: 0
};
const _hoisted_6 = {
  key: 1
};
const _hoisted_7 = {
  class: "mb-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_router_link = _resolveComponent("router-link");
  const _component_context_menu_item = _resolveComponent("context-menu-item");
  const _component_context_menu = _resolveComponent("context-menu");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.children, child => {
    return _openBlock(), _createBlock(_component_router_link, {
      key: child.id,
      to: {
        name: 'folder',
        params: {
          id: child.local_id,
          diskId: child.parent_id,
          accountId: child.account_id
        }
      },
      class: _normalizeClass(["list-group-item list-group-item-action", {
        'disabled': $setup.deletingId === child.id
      }]),
      "aria-current": "true"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("h5", _hoisted_3, [_createVNode(_component_font_awesome_icon, {
        icon: "folder",
        class: "me-2"
      }), _createTextVNode(" " + _toDisplayString(child.name), 1)]), _createElementVNode("div", {
        onClick: _withModifiers($event => $setup.onContextMenu($event, child.local_id, child.parent_id, child.description), ["prevent"])
      }, [$setup.loadingItemId === child.local_id ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_font_awesome_icon, {
        icon: "spinner",
        spin: ""
      })])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_font_awesome_icon, {
        size: "lg",
        icon: "ellipsis",
        class: "me-2"
      })]))], 8, _hoisted_4)]), _createElementVNode("p", _hoisted_7, _toDisplayString(child.description ? child.description : '(нет описания)'), 1)]),
      _: 2
    }, 1032, ["to", "class"]);
  }), 128))]), _createVNode(_component_context_menu, {
    show: $setup.show,
    "onUpdate:show": _cache[1] || (_cache[1] = $event => $setup.show = $event),
    options: $setup.optionsComponent
  }, {
    default: _withCtx(() => [_createVNode(_component_context_menu_item, {
      label: "Поделиться",
      icon: "icon-reload-1",
      onClick: _cache[0] || (_cache[0] = $event => $setup.share('share'))
    }), _createVNode(_component_context_menu_item, {
      label: "Удалить",
      icon: "trash",
      onClick: $setup.deletePrompt
    }, null, 8, ["onClick"])]),
    _: 1
  }, 8, ["show", "options"])], 64);
}