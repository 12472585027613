import {createApp} from 'vue';
import App from './App.vue';
import router from './router.js';

import './styles/main.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css';

import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {
    faCirclePlus,
    faCog,
    faCopy,
    faEllipsis,
    faFolder,
    faFolderPlus,
    faLink,
    faShareNodes,
    faSpinner,
    faTrash,
    faUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons';
import ContextMenu from '@imengyu/vue3-context-menu';
import {initUtils} from '@telegram-apps/sdk';
import {BButton, BModal, BProgress, BToast, createBootstrap} from "bootstrap-vue-next";
import {convertInitData, tgsdk} from './telegram.js';

const app = createApp(App);
const sdk = tgsdk();
let init = sdk.initData;

if (process.env.NODE_ENV === 'development') {
    init = 'user=%7B%22id%22%3A5000436777%2C%22first_name%22%3A%22Tolya%22%2C%22last_name%22%3A%22Blyat%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=5287150455624473448&chat_type=private&auth_date=1719091734&hash=f3f83896f64b904a44ca5b11a676f4771dd503cced269e7c611d28cce2bbe931&start_params=kF4Qkd_ATFtpW';
}

let data = {};

if (init) {
    data = convertInitData(init)
}

library.add(
    faCog,
    faFolder,
    faFolderPlus,
    faTrash,
    faUpRightFromSquare,
    faCirclePlus,
    faLink,
    faEllipsis,
    faShareNodes,
    faSpinner,
    faCopy
);

app.provide('utils', initUtils)
    .provide('tgSDK', sdk)
    .provide('initData', data)
    .provide('tgUser', data === {} ? null : data.user);

app.component('BModal', BModal)
    .component('BToast', BToast)
    .component('BButton', BButton)
    .component('b-progress', BProgress)
    .component('font-awesome-icon', FontAwesomeIcon);

app.use(router)
    .use(ContextMenu)
    .use(createBootstrap());

app.mount('#app');