import FoldersItem from "@/components/FolderItem.vue";
import FoldersList from "@/components/FoldersList.vue";
import ModelComponent from "@/components/ModelComponent.vue";
import { inject, onBeforeMount, ref } from "vue";
import { useToast } from "bootstrap-vue-next";
import api from "@/axios";
import OwnerBlock from "@/components/OwnerBlock.vue";
export default {
  name: 'ShareVIew',
  components: {
    OwnerBlock,
    ModelComponent,
    FoldersList,
    FoldersItem
  },
  setup(_, {
    emit
  }) {
    const {
      show
    } = useToast();
    const folder = ref(null);
    const loading = ref(true);
    const fName = ref('');
    const lName = ref('');
    const sdk = inject('tgSDK');
    const init = inject('initData');
    const showPopup = child => {
      sdk.showPopup({
        message: "Перейти по ссылке?\n" + child.name,
        buttons: [{
          id: 'yes',
          type: 'default',
          text: 'Да'
        }, {
          id: 'no',
          type: 'default',
          text: 'Нет'
        }]
      }, buttonId => {
        if (buttonId === 'yes') {
          window.open(child.name, '_blank');
        }
      });
    };
    const handleItemClick = child => {
      showPopup(child);
    };
    const fetch = async () => {
      const regex = /^[a-z0-9]+_[a-z0-9]+$/i;
      if (regex.test(init.start_param)) {
        const resp = await api.get(`/api/v1/shares/${init.start_param}`);
        folder.value = resp.data;
        lName.value = resp.headers.get('x-owner-last-name');
        fName.value = resp.headers.get('x-owner-first-name');
      } else {
        show({
          props: {
            body: 'Не корректная shared ссылка',
            value: 2000,
            variant: 'danger'
          }
        });
      }
    };
    onBeforeMount(async () => {
      await fetch();
      loading.value = false;
    });
    const shortLink = (text, maxLength = 32) => {
      if (text.length > maxLength) {
        let truncated = text.slice(0, maxLength);
        truncated = truncated.replace(/[^a-zA-Z0-9]+$/, '');
        return truncated + '...';
      }
      return text;
    };
    const toAccount = () => {
      emit('closeShare');
    };
    return {
      folder,
      loading,
      fName,
      lName,
      toAccount,
      shortLink,
      handleItemClick
    };
  }
};