export default {
  name: 'OwnerBlock',
  props: {
    fName: {
      type: String,
      required: true
    },
    lName: {
      type: String,
      required: true
    }
  }
};