export function tgsdk() {
    if (typeof window.Telegram !== 'undefined') {
        return window.Telegram?.WebApp;
    } else {
        console.error('Failed to load telegram WebApp SDK');
        return null;
    }
}

export function convertInitData(initData) {
    let data = Object.fromEntries(new URLSearchParams(initData).entries());
    data.user = JSON.parse(data.user);

    return data;
}
