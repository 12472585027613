import { onMounted, ref } from 'vue';
import FoldersList from "@/components/FoldersList.vue";
import ModelComponent from "@/components/ModelComponent.vue";
import api from "@/axios";
export default {
  name: 'DiskList',
  emits: ['data-fetched'],
  components: {
    FoldersList,
    ModelComponent
  },
  props: {
    account: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const folders = ref([]);
    const accountId = ref(0);
    const loading = ref(true);
    onMounted(async () => {
      try {
        const response = await api.get(`/api/v1/disks/${props.account.disks[0].local_id}`, {
          headers: {
            Authorization: `Bearer ${props.account.id}`
          }
        });
        accountId.value = response.data.account_id;
        folders.value = response.data.children;
      } catch (error) {
        console.error('Error fetching disks:', error);
      } finally {
        loading.value = false;
      }
    });
    const addRecord = record => {
      folders.value = [...folders.value, record];
    };
    return {
      folders,
      loading,
      accountId,
      addRecord
    };
  }
};