import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-007bba4e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "row flex-grow-1"
};
const _hoisted_2 = {
  class: "col-12 d-flex flex-column gap-2"
};
const _hoisted_3 = {
  class: "info-block flex-shrink-0"
};
const _hoisted_4 = {
  class: "d-flex justify-content-between align-items-center p-2 header"
};
const _hoisted_5 = {
  class: "content-block flex-grow-1 d-flex flex-column"
};
const _hoisted_6 = {
  key: 0,
  class: "flex-grow-1"
};
const _hoisted_7 = {
  class: "folders-place list-group list-group-flush"
};
const _hoisted_8 = ["onClick"];
const _hoisted_9 = ["onClick"];
const _hoisted_10 = {
  key: 0
};
const _hoisted_11 = {
  key: 1
};
const _hoisted_12 = {
  class: "mb-1"
};
const _hoisted_13 = {
  key: 1,
  class: "list-group-item"
};
const _hoisted_14 = {
  class: "d-flex justify-content-between mt-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_ModelComponent = _resolveComponent("ModelComponent");
  const _component_context_menu_item = _resolveComponent("context-menu-item");
  const _component_context_menu = _resolveComponent("context-menu");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("main", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("section", _hoisted_4, [_createElementVNode("div", null, _toDisplayString($setup.folder.description ? $setup.folder.description : 'Нет описания папки'), 1)])]), _createElementVNode("div", _hoisted_5, [$setup.folder.children.length ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.folder.children, child => {
    return _openBlock(), _createElementBlock("div", {
      key: child.id,
      class: "list-group-item list-group-item-action",
      "aria-current": "true"
    }, [_createElementVNode("div", {
      class: "d-flex w-100 justify-content-between",
      onClick: $event => $setup.handleItemClick(child)
    }, [_createElementVNode("h5", null, [_createVNode(_component_font_awesome_icon, {
      icon: "up-right-from-square",
      class: "me-2"
    }), _createTextVNode(_toDisplayString($setup.shortLink(child.name)), 1)]), _createElementVNode("div", {
      onClick: _withModifiers($event => $setup.onContextMenu($event, child.local_id, child.name, child.description), ["prevent"])
    }, [$setup.loadingItemId === child.local_id ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_font_awesome_icon, {
      icon: "spinner",
      spin: ""
    })])) : (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_font_awesome_icon, {
      size: "lg",
      icon: "ellipsis",
      class: "me-2"
    })]))], 8, _hoisted_9)], 8, _hoisted_8), _createElementVNode("p", _hoisted_12, _toDisplayString(child.description ? child.description : 'Нет описания'), 1)]);
  }), 128))])])) : (_openBlock(), _createElementBlock("div", _hoisted_13, "Пусто"))]), _createElementVNode("footer", _hoisted_14, [_createVNode(_component_ModelComponent, {
    onRecordAdded: $options.addRecord,
    "parent-id": $setup.folder.local_id,
    "account-id": $props.accountId,
    label: "Ссылка",
    "label-desc": "Название",
    type: "link",
    icon: "🔗"
  }, null, 8, ["onRecordAdded", "parent-id", "account-id"])])])]), _createVNode(_component_context_menu, {
    show: $setup.isShow,
    "onUpdate:show": _cache[3] || (_cache[3] = $event => $setup.isShow = $event),
    options: $setup.optionsComponent
  }, {
    default: _withCtx(() => [_createVNode(_component_context_menu_item, {
      label: "Скопировать",
      icon: "icon-reload-1",
      style: {
        "display": "flex",
        "align-items": "center"
      },
      onClick: _cache[0] || (_cache[0] = $event => $options.copyToBuffer())
    }), _createVNode(_component_context_menu_item, {
      label: "Поделиться",
      icon: "icon-reload-1",
      onClick: _cache[1] || (_cache[1] = $event => $setup.shareLink())
    }), _createVNode(_component_context_menu_item, {
      label: "Удалить",
      icon: "trash",
      onClick: _cache[2] || (_cache[2] = $event => $setup.deletePrompt())
    })]),
    _: 1
  }, 8, ["show", "options"])], 64);
}