export default {
  name: 'AccountLink',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    return {
      userAvatar: require('@/assets/user-avatar.png'),
      firstName: props.user.first_name,
      lastName: props.user.last_name
    };
  }
};